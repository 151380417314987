import { WindowLocation } from '@reach/router';
import { useEffect } from 'react';
import { useSetAtom } from 'jotai';
import { useDecision } from '@optimizely/react-sdk';

import { useAppContext } from '@contexts/AppContext';
import { AbsPage } from '@src/modules/AnnualBoilerService/Abs';
import { isAgentAtom } from '@src/store/store';
import { QuotesPlatformAbs } from '@src/modules/AnnualBoilerService/QuotesPlatformAbs';
import { OptimizelyFeatureFlag } from '@entities/optimizely';

interface Props {
  location: WindowLocation;
}

const AbsAgent = (props: Props) => {
  const { verifyNoSignupInLocalStorage } = useAppContext();
  const setIsAgent = useSetAtom(isAgentAtom);
  const [{ enabled: isQuotesPlatform }] = useDecision(OptimizelyFeatureFlag.QUOTE_PLATFORM_ROLLOUT);

  useEffect(() => {
    verifyNoSignupInLocalStorage();
    setIsAgent(true);
  }, [verifyNoSignupInLocalStorage]);

  if (isQuotesPlatform) {
    return <QuotesPlatformAbs location={props.location} />;
  }

  return <AbsPage {...props} />;
};

export default AbsAgent;
